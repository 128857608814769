import { NgClass, NgOptimizedImage, SlicePipe } from '@angular/common';
import { booleanAttribute, ChangeDetectionStrategy, Component, computed, HostBinding, inject, input, OnInit, output, signal } from '@angular/core';
import { RouterLink } from '@angular/router';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { faBookmark as faBookmarkSolid, faLock, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { ProgressBarModule } from 'primeng/progressbar';
import { BookmarkService } from 'src/app/@domain/bookmark/application/bookmark.service';
import { PlaylistService } from 'src/app/@domain/playlist/application/playlist.service';
import { ImageDirective } from '../../../../@common/DOM/image.directive';
import { TransformTimestampPipe } from '../../../../@common/pipe/transform-timestamp.pipe';
import { PublishedStatus, VideoInfo, VideoPartInfo } from '../../../../@generated';
import { AppRoutes } from '../../../../routing/types/app-route';
import { BadgeComponent } from '../../../global/badge/badge.component';

@Component({
    selector: 'app-video-item',
    templateUrl: './video-item.component.html',
    styleUrls: [ './video-item.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ RouterLink, BadgeComponent, ImageDirective, FaIconComponent, NgClass, ConfirmationPopoverModule, TransformTimestampPipe, ProgressBarModule, SlicePipe, NgOptimizedImage ]
})
export class VideoItemComponent implements OnInit {
    private bookmarksService = inject(BookmarkService);
    private playlistService = inject(PlaylistService);

    @HostBinding('hidden') public wasRemoved: boolean = false;
    public video = input.required<VideoInfo>();
    public part = input.required<VideoPartInfo | undefined>();
    public playlistId = input<number | null>();
    public canDelete = input(false);
    public isBookmark = input(false, { transform: booleanAttribute });
    public isPlaylist = computed(() => !!this.playlistId());
    public listView = input(false, { transform: booleanAttribute });
    public priorityImageLoading = input(false, { transform: booleanAttribute });
    public onRemove = output();
    public customLectureTitle: string = '';
    public minutesLeft!: number;
    public progress!: number;
    public faBookmarkSolid = faBookmarkSolid;
    public faTrash = faTrash;
    public faLock = faLock;
    public AppRoutes = AppRoutes;
    public PublishedStatus = PublishedStatus;

    public ngOnInit(): void {
        //remove this
        this.customLectureTitle = this.cutTitle();

        const {duration = 0, left_off = 0} = this.part() ?? {};
        this.minutesLeft = Math.round((duration - left_off) / 60);
        this.progress = Math.round((left_off * 100) / duration);
    }

    public cutTitle() {
        if (this.video().title.split('').length <= 80) {
            return this.video().title;
        }

        return this.video().title.slice(0, 80) + '...';
    }

    public async removeFromBookmarksOrPlaylist() {
        if (this.isBookmark()) {
            await this.bookmarksService.removeBookmark(this.video().id);
            this.onRemove.emit();
        }

        if (this.playlistId()) {
            await this.playlistService.removeVideoFromPlaylist(this.playlistId()!, this.video().id);
            this.onRemove.emit();
        }
    }
}
