@if (!listView()) {
    <a class="card-item" routerLink="/{{AppRoutes.Videos}}/{{video().slug}}/">
        <div class="card-item-header">
            @if (video().status !== PublishedStatus.Public) {
                <div class="private-badge">
                    <fa-icon [icon]="faLock" />
                    Private
                </div>
            }

            <img [src]="part()?.thumbnail" alt="video-img"
                 [attr.loading]="priorityImageLoading() ? 'eager' : 'lazy'"
                 [attr.fetchpriority]="priorityImageLoading() ? 'high' : 'low'"
                 decoding="async"
            />

            @if (part()?.duration; as duration) {
                <div class="badge">{{ duration | transformTimestamp: 's' }}</div>
            }
        </div>
        <div class="card-item-body">
            <h3>{{ customLectureTitle }}</h3>
            <div class="authors">
                @for (author of video().authors; track author.id; let last = $last) {
                    <h4 class="author">{{ author.name + (last ? '' : ',') }}</h4>
                }
            </div>

            <div class="info">
                <p>{{ video().created }}</p>
                <p>&nbsp;·&nbsp;</p>
                <p>{{ video().total_views }} Views</p>
            </div>
            @if (video().type || canDelete()) {
                <div class="d-flex flex-wrap" [class.delete]="canDelete()">
                    <app-badge [text]="video().type" />
                    @if (canDelete()) {
                        <fa-icon class="delete-icon" [icon]="faTrash" />
                    }
                </div>
            }
        </div>
        @if (progress) {
            <div class="progress-bar">
                <p>{{ minutesLeft ? minutesLeft + " min left" : " " }}</p>
                <p-progressBar [style]="{ height: '4px'}" color="#FF2E59" [value]="progress" [showValue]="false" />
            </div>
        }
    </a>
} @else {
    <div [ngClass]="(isPlaylist() || isBookmark()) ? 'list-view-playlist' : 'video-list-view'" class="list-view">
        @if (isBookmark()) {
            <fa-icon
                    class="icon"
                    mwlConfirmationPopover
                    popoverTitle="Delete confirmation"
                    popoverMessage="Are you sure you want to remove the lecture from bookmarks?"
                    placement="right"
                    (confirm)="removeFromBookmarksOrPlaylist()"
                    [icon]="faBookmarkSolid"
            />
        }
        @if (isPlaylist()) {
            <fa-icon
                    class="icon"
                    mwlConfirmationPopover
                    popoverTitle="Delete confirmation"
                    popoverMessage="Are you sure you want to remove the lecture from playlist?"
                    placement="right"
                    (confirm)="removeFromBookmarksOrPlaylist()"
                    [icon]="faTrash"
            />
        }
        <a routerLink="/{{AppRoutes.Videos}}/{{video().slug}}">
            <img [src]="part()?.thumbnail" [alt]="video().title  + 'image'"
                 [attr.loading]="priorityImageLoading() ? 'eager': 'lazy'" />
        </a>
        <a class="right" routerLink="/{{AppRoutes.Videos}}/{{video().slug}}">
            <div class="info">
                <h3 class="title">{{ video().title }}</h3>

                @if (video().authors.length) {
                    <div class="authors">
                        @for (author of video().authors; track author; let isLast = $last) {
                            <h4 class="author">
                                {{ author.name }}@if (!isLast) {
                                <span>,&nbsp;</span>
                            }
                            </h4>
                        }
                    </div>
                }
            </div>

            <div class="numbers">
                <p>{{ video().created }}</p>
                <p>{{ video().total_views }} Views</p>

                @if ((isPlaylist() || isBookmark()) && video().type; as type) {
                    <app-badge [text]="type" />
                }
            </div>
        </a>
    </div>
}
