import { inject, Injectable } from '@angular/core';
import { catchError, firstValueFrom, Observable, of, shareReplay, take } from 'rxjs';
import { Logger } from 'src/app/@common/log/logger';
import { Page } from '../../../@common/entities/page.interface';
import { SortOrder, V2BookmarksResource, VideoInfo } from '../../../@generated';

@Injectable({
    providedIn: 'root',
})
export class BookmarkService {
    private logger = inject(Logger);
    private bookmarkResource = inject(V2BookmarksResource);

    public getBookmarkedVideos(limit: number, page: number, order: SortOrder): Observable<Page<VideoInfo>> {
        return this.bookmarkResource.getBookmarks(limit, page, order).pipe(
            shareReplay(1),
            catchError(e => {
                this.logger.apiError('Get bookmarked videos failed', e);
                return of({ count: 0, results: [] });
            })
        );
    }

    public async addBookmark(videoId: number) {
        try {
            await firstValueFrom(this.bookmarkResource.addBookmark(videoId));
        } catch (e) {
            this.logger.apiError('Bookmark video failed', e);
            throw e;
        }
    }

    public async removeBookmark(videoId: number) {
        try {
            await firstValueFrom(this.bookmarkResource.removeBookmark(videoId));
        } catch (e) {
            this.logger.apiError('Unbookmark video failed', e);
            throw e;
        }
    }

}
