import { TitleCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-badge',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        TitleCasePipe
    ],
    styles: `
        @import "../../../../styles/variables";

        :host {
            border: 1px solid $grey;
            padding: 0 12px 2px;
            border-radius: 15px;
            width: fit-content;
        }

        small {
            font-size: 14px;
            color: $black;
        }
    `,
    template: `<small>{{ text | titlecase }}</small>`
})
export class BadgeComponent {
  @Input({required: true}) text: string = '';
}
